import React, { ReactText } from 'react';

import { PermafrostComponent } from 'Permafrost/types';

import { faIcons } from './faIcons';
import { indicons } from './indicons';
import { partnerIcons } from './partnerIcons';

type IconComponentProps = {
  children: React.ReactNode;
  fill: string;
  viewBox: string;
};

export type IconName = keyof typeof allIcons;

type Props = PermafrostComponent & {
  ariaLabel?: string;
  fill?: string;
  name: IconName;
  /* `[value]` or `[width, height]` */
  size?: [ReactText] | [ReactText, ReactText];
  style?: any;
  onClick?(): void;
};

const allIcons = {
  ...faIcons,
  ...indicons,
  ...partnerIcons,
};

/**
 * Generic icon component, hidden from Assistive Technology by default.
 * If icon should be available to AT, pass the desired name into the `ariaLabel` prop.
 */
export function Icon({ style = {}, ...props }: Props): React.ReactElement | null {
  const ariaLabel = props.ariaLabel
    ? { 'aria-label': props.ariaLabel }
    : { 'aria-hidden': true, focusable: false };
  const iconComponentProps: IconComponentProps = allIcons[props.name]?.props || null;
  const dimensions =
    props.size && props.size.length > 0
      ? returnDimensions(props.size)
      : { width: '1em', height: '1em' };

  if (!iconComponentProps) {
    console.error(`${props.name} is not a valid icon name.`);

    return null;
  }

  return (
    <svg
      {...iconComponentProps}
      {...ariaLabel}
      {...dimensions}
      className={props.className}
      data-cy={props['data-cy']}
      fill={props.fill || 'currentColor'}
      id={props.id}
      style={style}
      onClick={props.onClick}
    />
  );
}

function returnDimensions(sizeProp: [ReactText] | [ReactText, ReactText]) {
  if (sizeProp.length > 1) {
    return { width: sizeProp[0], height: sizeProp[1] };
  }

  return { width: sizeProp[0], height: sizeProp[0] };
}
