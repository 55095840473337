import React from 'react';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledModalBase } from './ModalBase.styles';

export type ModalBaseProps = PermafrostComponent & {
  children: React.ReactNode | React.ReactNode[];
  clickToDismiss?(): void;
  describedBy?: string;
  /** a selector representing the desired parent; defaults to `#root` */
  node?: string;
  open: boolean;
  preventEscDismiss?: boolean;
  preventReturnFocus?: boolean;
} & ({ labelElement: string } | { labelText: string });

/**
 * Base modal component, powered by [react-modal](http://reactcommunity.org/react-modal/#usage)
 *
 * (Also see `ModalAdapter` in the styles file)
 *
 * Accessible element label must be included either as a string passed to `labelText`, or
 * the id of one of the children passed to `labelElement`. If a description is available,
 * pass that element’s id to the `describedBy` prop.
 */
export function ModalBase(props: ModalBaseProps) {
  const {
    children,
    className,
    clickToDismiss,
    describedBy,
    id,
    node,
    open,
    preventEscDismiss,
    preventReturnFocus,
  } = props;

  const ariaProps = {} as { labelledby?: string; describedby?: string };

  if ('labelElement' in props) {
    ariaProps['labelledby'] = props.labelElement;
  }

  if (describedBy) {
    ariaProps['describedby'] = describedBy;
  }

  return (
    <StyledModalBase
      ariaHideApp={false}
      data-cy={props['data-cy']}
      aria={ariaProps}
      className={className || 'baseModal'}
      contentLabel={'labelText' in props ? props.labelText : null}
      id={id}
      isOpen={open}
      node={node}
      onRequestClose={clickToDismiss}
      shouldCloseOnEsc={!preventEscDismiss}
      shouldReturnFocusAfterClose={!preventReturnFocus}
    >
      {children}
    </StyledModalBase>
  );
}
