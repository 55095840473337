import React, { useState, useEffect } from 'react';

import { TabbedInterface, SectionTab } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type Section = SectionTab & { content: React.ReactNode };
type SectionContent = { [sectionId: string]: React.ReactNode };
type TabIdAndName = { id: string; name: string };

type Props = PermafrostComponent & {
  defaultSection?: string;
  fullWidthTabs?: boolean;
  sections: Section[];
  current: string;
  setCurrent: (id: string) => void;
};

/**
 * An a11y-friendly tabbed interface; allows the user to tab between different sections
 * of a container.
 *
 * If the URL will change as the user moves from section to section, the `TabNavigation` component
 * should be used instead.
 */
export function TabbedContainer(props: Props) {
  const { className, defaultSection, fullWidthTabs, id, sections, current, setCurrent } = props;
  const [tabs, setTabs] = useState<TabIdAndName[]>([] as TabIdAndName[]);
  const [sectionContent, setSectionContent] = useState<SectionContent>({} as SectionContent);

  // If the sections (aka tabs) we passed in change,
  // then update our local state and,
  // if the tab we were on no longer exists,
  // then return to the default selection
  useEffect(() => {
    setTabs(sections.map((section) => ({ id: section.id, name: section.name as string })));
    setSectionContent(
      sections.reduce((acc, section) => {
        return {
          ...acc,
          [section.id]: section.content,
        };
      }, {})
    );
    if (!sections.some((section) => section.id === current)) {
      setCurrent(defaultSection || sections[0].id);
    }
  }, [sections]);

  return (
    <TabbedInterface
      tabType="section"
      tabs={tabs}
      className={className}
      currentTab={current}
      data-cy={props['data-cy']}
      id={id}
      fullWidthTabs={fullWidthTabs}
      onTabSelect={(id) => setCurrent(id)}
    >
      {sectionContent[current]}
    </TabbedInterface>
  );
}
