import styled from 'styled-components';

import { COLORS } from 'Permafrost/index';

export const StyledSectionBlock = styled.div`
  h3 {
    margin: 24px 0 31px;
  }

  hr {
    width: 100%;
    border-bottom: 1px solid ${COLORS.ebony};
    margin: 27px 0 1px;
  }
`;
