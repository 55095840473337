import React from 'react';

import classNames from 'classnames';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledSectionBlock } from './SectionBlock.styles';

type Props = PermafrostComponent & {
  title?: string;
  style?: object;
  children: React.ReactNode;
};

export const SectionBlock = (props: Props) => {
  const { title, className, children, id, style = {}, ...restOfProps } = props;

  return (
    <StyledSectionBlock
      className={classNames('SectionBlock', className)}
      data-cy={props['data-cy']}
      id={id}
      style={style}
      {...restOfProps}
    >
      {title ? (
        <header>
          <h3>{title}</h3>
        </header>
      ) : null}
      {children}
      <hr className="section-bottom-border" />
    </StyledSectionBlock>
  );
};
