import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon, COLORS } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledTooltip } from './Tooltip.styles';

type Props = PermafrostComponent & {
  questionMark?: boolean;
  for?: string | null;
  place?: string;
  clickToShow?: boolean;
  delayShow?: number;
  delayHide?: number;
  questionMarkClassName?: string;
  children: React.ReactNode;
};

export const Tooltip = (props: Props) => {
  return (
    <StyledTooltip className={props.className} data-cy={props['data-cy']} id={props.id}>
      {props.questionMark && (
        <i
          style={{ color: COLORS.curiousBlue }}
          className={props.questionMarkClassName}
          data-tip={props.clickToShow ? 'custom show' : ''}
          data-event={props.clickToShow ? 'click focus' : ''}
          data-for={props.for}
        >
          <Icon name="fa-question-circle" />
        </i>
      )}
      <ReactTooltip
        className="tool"
        globalEventOff={props.clickToShow ? 'click' : ''}
        place={props.place || 'top'}
        id={props.for}
        delayShow={props.delayShow}
        delayHide={props.delayHide}
      >
        {props.children}
      </ReactTooltip>
    </StyledTooltip>
  );
};
