import React, { ChangeEventHandler, useRef, useState, useEffect } from 'react';

import { format, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';

import { PermafrostComponent } from 'Permafrost/types';
import { IconButton } from 'Permafrost/index';

import { StyledDatePicker } from './DatePicker.styles';

type Props = PermafrostComponent & {
  dataCy: string;
  ariaLabel?: string;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  disabled?: boolean;
  id: string;
  label?: string;
  onChange: (value: Date | undefined) => void;
  placeholder?: string;
  defaultSelected?: Date;
  value?: Date | '';
};

export const DatePicker = (props: Props) => {
  const {
    dataCy,
    ariaLabel,
    className,
    disabled,
    disableBeforeDate,
    disableAfterDate,
    id,
    label,
    onChange,
    placeholder,
    defaultSelected,
    value,
  } = props;

  const initSelected = () => {
    if (value) {
      return isValid(value) ? value : undefined;
    }
    return isValid(defaultSelected) ? defaultSelected : undefined;
  };

  const [selected, setSelected] = useState<Date | undefined>(initSelected());
  const [inputValue, setInputValue] = useState<string>(
    isValid(value) ? format(value as Date, 'y-MM-dd') : ''
  );
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  // When external state changes, we update local state to match, if not already matching (avoid infinite loop)
  useEffect(() => {
    const valueEmpty = value === null || value === undefined || value === '' ? true : false; // Can be empty string since controlled components do NOT want null/undefined
    const selectedEmpty = selected === null || selected === undefined ? true : false; // Should never be empty string since datepicker dependency expects undefined, NOT empty string

    if (
      (valueEmpty && selectedEmpty) ||
      (isValid(value) &&
        isValid(selected) &&
        format(value as Date, 'y-MM-dd') === format(selected as Date, 'y-MM-dd'))
    ) {
      return;
    }

    if (value && isValid(value)) {
      setInputValue(format(value as Date, 'y-MM-dd'));
      setSelected(value as Date);
    } else {
      setSelected(undefined);
      setInputValue('');
    }
  }, [value]);

  // todo: this is problematic because it means on mount the onChange handler will always be fired
  // therefore - if the onChange is some heavy operation or call to the BE - that will automatically get called
  // without the user even doing anything
  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      setSelected(date);
    } else {
      setSelected(undefined);
    }
  };

  const handleDaySelect = (date: Date | undefined) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, 'y-MM-dd'));
      closePopper();
    } else {
      setInputValue('');
    }
  };

  return (
    <StyledDatePicker
      className={className}
      aria-label={ariaLabel || 'date select'}
      aria-describedby={`picker-label--${id}`}
      data-cy={dataCy}
      id={id}
    >
      {label ? (
        <div id={`picker-label--${id}`} className="datepicker-label">
          {label}
        </div>
      ) : null}
      <div ref={popperRef} className="datepicker--inputParent">
        <input
          disabled={disabled}
          type="text"
          placeholder={placeholder || format(new Date(), 'y-MM-dd')}
          value={inputValue}
          onChange={handleInputChange}
          className="datepicker--input"
          onClick={() => setIsPopperOpen(!isPopperOpen)}
        />
        <IconButton
          className="remove-btn"
          iconName="x-close"
          onPress={() => {
            closePopper();
            setSelected(undefined);
            setInputValue('');
          }}
        />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="DayPickerInput-Overlay"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              disabled={disabled}
              mode="single"
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              fromDate={disableBeforeDate}
              toDate={disableAfterDate}
            />
          </div>
        </FocusTrap>
      )}
    </StyledDatePicker>
  );
};
