import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import { PermafrostComponent } from 'Permafrost/types';

import { StyledValue, StyledHeaderValues } from './HeaderValues.styles';

type Common = {
  text: string;
  'data-cy'?: string;
};

export type ValueItem = Common & {
  value: number | string | React.ReactElement;
};

export type LinkItem = Common & {
  to: LinkProps['to'];
};

type Props = PermafrostComponent & {
  values?: ValueItem[];
  links?: LinkItem[];
};

/**
 * Found on item detail pages. Generates a styled list of ids and/or links
 * related to the current item.
 */
export function HeaderValues(props: Props) {
  const { values, links, className, id } = props;

  return (
    <StyledHeaderValues className={className} data-cy={props['data-cy']} id={id}>
      {values?.map((value) => (
        <StyledValue key={`${value.text}-${value.value}`} data-cy={value['data-cy']}>
          <span className="header-text">{value.text}: </span>
          <span className="header-value"> {value.value}</span>
        </StyledValue>
      ))}

      {links?.map((link) => (
        <li key={`${link.text}-${link.to}`} data-cy={link['data-cy']}>
          <Link to={link.to}>{link.text}</Link>
        </li>
      ))}
    </StyledHeaderValues>
  );
}
