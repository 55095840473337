import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../../constants';

export const UtilityClasses = createGlobalStyle`
  .link-style {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .spin {
    animation: spin 2s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  
  .card-body {
    background-color: ${COLORS.clay};
    border-radius: 4px;
    border: 1px solid ${COLORS.oxfordBlue};
    padding: 20px;
    
    hr {
    margin: 1.25em 0;
      border: 1px solid transparent;
      border-bottom-color: ${COLORS.ebony};
    }
  }
  
  .scrollable-vertical {
    overflow: hidden auto;
  
    scrollbar-color: ${COLORS.darkGray} transparent;
    scrollbar-gutter: stable;
    scrollbar-width: thin;
  
    &::-webkit-scrollbar {
      width: 5px;
    }
  
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
      border-radius: 20px;
    }
  }

  .page-bottom {
    padding-bottom: 20px;
  }
`;
