import React from 'react';

import { Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

type Props = PermafrostComponent & {
  size?: string;
  style?: { [key: string]: string };
};

export function LoadingSpinner(props: Props): React.ReactElement {
  const { className, id, size, style } = props;

  const loaderSize = size || '12px';
  const loaderStyle = {
    position: 'relative',
    top: '1px',
    ...style,
  };

  return (
    <Icon
      name="fa-circle-notch"
      className={`spin ${className || ''}`}
      data-cy={props['data-cy']}
      id={id}
      size={[loaderSize]}
      style={loaderStyle}
    />
  );
}
