import React, { createContext, useRef } from 'react';

import classNames from 'classnames';

import { AriaCheckboxGroupProps } from '@react-types/checkbox';
import { useCheckboxGroup, useCheckboxGroupItem } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { useVisuallyHidden } from '@react-aria/visually-hidden';

import { PermafrostComponent, PillProps } from 'Permafrost/types';
import { Icon, Tooltip } from 'Permafrost/index';

import { StyledPillCheckGroupBase } from './PillCheckGroupBase.styles';
import { StyledPill } from './Pill.styles';

type PillCheckGroupState = {
  isDisabled: boolean;
  isReadOnly: boolean;
  value: string[];
  addValue(newValue: string): void;
  isSelected(newValue: string): boolean;
  removeValue(newValue: string): void;
  setValue(newValue: string[]): void;
  toggleValue(newValue: string): void;
};

export type PillCheckGroupProps = PermafrostComponent & {
  id?: string;
  children: React.ReactNode;
  className?: string;
  disableGroup?: boolean;
  enforceSingleValue?: boolean;
  isReadOnly?: boolean;
  label?: string;
  verticalOrientation?: boolean;
  values: string[];
  onChange: (value: string[]) => void;
} & AriaCheckboxGroupProps;

export const PillCheckGroupContext = createContext({} as PillCheckGroupState);

/**
 * Renders a group of 'pill checkboxes'.
 *
 * A group label must be included: either pass a string or markup into the
 * `label` prop, or include an `aria-label` or `aria-labelledby` attribute.
 */
export function PillCheckGroupBase(props: PillCheckGroupProps) {
  const {
    id,
    children,
    className,
    disableGroup = false,
    isReadOnly = false,
    label,
    enforceSingleValue = false,
    onChange,
    verticalOrientation,
    values,
  } = props;

  // NOTE: React-Aria checkboxgroup NEVER uses 'setValue' or 'toggleValue';
  // however, we could call them expliciitly if we ever choose (which we probably won't).
  // Rather, it relies only on 'addValue' and 'removeValue'; therefore the latter have both
  // been changed to reflect the multi vs single selection functionality desired for this component.
  // All methods are required for TS validation.
  const pillCheckGroupState = {
    value: values,
    setValue(newValue: string[]) {
      if (isReadOnly || disableGroup) {
        return;
      }
      onChange(newValue);
    },
    isDisabled: disableGroup,
    isReadOnly: isReadOnly,
    isSelected(newValue: string) {
      return values.includes(newValue);
    },
    addValue(newValue: string) {
      if (isReadOnly || disableGroup) {
        return;
      }
      if (!values.includes(newValue)) {
        if (enforceSingleValue) {
          // there should only be a single value in the array
          onChange([newValue]);
        } else {
          onChange(values.concat(newValue));
        }
      }
    },
    removeValue(newValue: string) {
      if (isReadOnly || disableGroup) {
        return;
      }
      if (values.includes(newValue)) {
        onChange(values.filter((existingValue: string) => existingValue !== newValue));
      }
    },
    toggleValue(newValue: string) {
      if (isReadOnly || disableGroup) {
        return;
      }
      if (values.includes(newValue)) {
        onChange(values.filter((existingValue: string) => existingValue !== newValue));
      } else {
        onChange(values.concat(newValue));
      }
    },
  };

  const { groupProps, labelProps } = useCheckboxGroup(props, pillCheckGroupState);

  return (
    <StyledPillCheckGroupBase
      id={id}
      className={classNames(className, {
        'PillCheckGroupBase--horizontal': verticalOrientation !== true,
        'PillCheckGroupBase--disabled': disableGroup,
      })}
      data-cy={props['data-cy']}
      {...groupProps}
    >
      {label && (
        <div className="PillCheckGroup__group-label" {...labelProps}>
          {label}
        </div>
      )}

      <PillCheckGroupContext.Provider value={pillCheckGroupState}>
        {children}
      </PillCheckGroupContext.Provider>
    </StyledPillCheckGroupBase>
  );
}

export function Pill(props: PillProps) {
  const {
    id,
    caption,
    captionColor,
    children,
    className,
    fixedWidth,
    isDisabled = false,
    isSelected,
    pillTextColor,
    tooltip,
  } = props;

  const state = React.useContext(PillCheckGroupContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckboxGroupItem(props, state, inputRef);

  const { isFocusVisible, focusProps } = useFocusRing();
  const { visuallyHiddenProps } = useVisuallyHidden();
  const checkMeasure = 12;

  return (
    <StyledPill
      id={id}
      captionColor={captionColor || 'inherit'}
      className={classNames({ 'Pill__label--checked': isSelected, isDisabled }, className)}
      data-cy={props['data-cy']}
      data-for={id}
      data-tip
      fixedWidth={fixedWidth}
      pillTextColor={pillTextColor || 'inherit'}
    >
      <input
        ref={inputRef}
        type="checkbox"
        {...focusProps}
        {...inputProps}
        {...visuallyHiddenProps}
      />
      <div
        className={classNames(
          className,
          {
            'Pill__pill-ellipse--focusRing': isFocusVisible === true,
            'Pill__pill-ellipse--selected': isSelected === true,
          },
          'pill-ellipse'
        )}
      >
        <span className="pill-text">{children}</span>
        {isSelected && <Icon name="check-circle" className="checkmark" size={[checkMeasure]} />}
      </div>
      {caption && <span className="caption">{caption}</span>}
      {tooltip ? <Tooltip for={id}>{tooltip}</Tooltip> : null}
    </StyledPill>
  );
}
