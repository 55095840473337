import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { hookUtils } from '@indico-data/utils';

import { Button, IconButton, Tooltip } from 'Permafrost/index';
import { KabobMenuOption, PermafrostComponent } from 'Permafrost/types';

import { StyledKabobMenu } from './KabobMenu.styles';

type Props = PermafrostComponent & {
  disabled?: boolean;
  leftAligned?: boolean;
  menuOptions: KabobMenuOption[];
};

export const KabobMenu = ({ id, className, disabled, leftAligned = false, menuOptions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  hookUtils.useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <StyledKabobMenu id={id} className={classNames(className, { 'left-aligned': leftAligned })}>
      <IconButton
        className={classNames('KabobMenu--kabob-btn', { 'no-background': !isOpen })}
        isDisabled={disabled}
        iconName="kabob"
        label=""
        onPress={() => setIsOpen(!isOpen)}
      />
      {isOpen ? (
        <div className="KabobMenu--menu" ref={menuRef}>
          {menuOptions.map((option) => {
            return (
              <div
                className={classNames('menu-item', { disabled: option.disabled })}
                data-tip
                data-for={option.id}
                key={option.id}
              >
                {option.iconName ? (
                  <IconButton
                    className="menu-item-btn"
                    isDisabled={option.disabled}
                    iconName={option.iconName}
                    label={option.label}
                    onPress={() => {
                      option.onClick();
                      setIsOpen(false);
                    }}
                  />
                ) : (
                  <Button
                    className="menu-item-btn"
                    disabled={option.disabled}
                    onClick={() => {
                      option.onClick();
                      setIsOpen(false);
                    }}
                  >
                    {option.label}
                  </Button>
                )}
                {option.disabledText && option.disabled ? (
                  <Tooltip for={option.id} questionMark={false}>
                    {option.disabledText}
                  </Tooltip>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </StyledKabobMenu>
  );
};
