import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { v4 as uuid } from 'uuid';

import { Button, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledNumberField } from './NumberField.styles';

export type Props = PermafrostComponent & {
  autoFocus?: boolean;
  decrement?(): void;
  disabled?: boolean;
  hiddenLabel?: boolean;
  increment?(): void;
  label: string;
  max?: number;
  min?: number;
  onChange(value: number | ''): void;
  placeholder?: string;
  required?: boolean;
  step?: string;
  tabIndex?: number;
  value: number | '';
};

/**
 * Basic numeric input field. A label is required, but may be visually hidden
 * using the `hiddenLabel` property.
 */
export function NumberField(props: Props): React.ReactElement {
  // ensures unique value to associate label with input
  const fieldId = uuid();
  const {
    autoFocus,
    className,
    disabled,
    hiddenLabel,
    id,
    label,
    max,
    min,
    onChange,
    placeholder,
    required,
    step,
    tabIndex,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState<number | ''>(
    value ? value : value === 0 ? 0 : ''
  );

  const inputEl = useRef<HTMLInputElement>(null);

  const increment = () => {
    const input = inputEl.current;
    if (input) {
      input.stepUp();
      // convert string value to number
      setCurrentValue(+input.value);
    }
    if (props.increment) props.increment();
  };

  const decrement = () => {
    const input = inputEl.current;
    if (input) {
      input.stepDown();
      // convert string value to number
      setCurrentValue(+input.value);
    }
    if (props.decrement) props.decrement();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event?.target?.value;
    setCurrentValue(targetValue === '' ? '' : +targetValue);
  };

  // When external state changes, we update local state to match, if not already
  useEffect(() => {
    if (currentValue === value) {
      return;
    }
    setCurrentValue(value);
  }, [value]);

  // todo: this is problematic because it means on mount the onChange handler will always be fired
  // therefore - if the onChange is some heavy operation or call to the BE - that will automatically get called
  // without the user even doing anything
  useEffect(() => {
    // We must debounce the min/max evaluation until after the user has finished typing
    const delayDebounceFn = setTimeout(() => {
      if ((min || min === 0) && +currentValue < min) {
        setCurrentValue('');
        onChange('');
      } else if ((max || max === 0) && +currentValue > max) {
        setCurrentValue('');
        onChange('');
      } else if (+currentValue < 0) {
        setCurrentValue('');
        onChange('');
      } else {
        onChange(currentValue);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [currentValue]);

  return (
    <StyledNumberField
      className={classNames(className, {
        hiddenLabel,
      })}
      data-cy={props['data-cy']}
      id={id}
    >
      <input
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        id={fieldId}
        type="number"
        value={currentValue}
        disabled={disabled}
        max={max}
        min={min}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        step={step}
        aria-label={hiddenLabel ? label : ''}
        ref={inputEl}
      />

      {!hiddenLabel && <label htmlFor={fieldId}>{label}</label>}

      {/* since user can increment/decrement using arrow keys, these don’t need to be tabbable */}
      <div className="spin-buttons">
        <Button variant="no-style" onClick={increment} tabindex={-1}>
          <Icon name="fa-caret-up" ariaLabel="increase" />
        </Button>

        <Button variant="no-style" onClick={decrement} tabindex={-1}>
          <Icon name="fa-caret-down" ariaLabel="decrease" />
        </Button>
      </div>
    </StyledNumberField>
  );
}
