import React, { useState } from 'react';

import classNames from 'classnames';

import { arrayUtils } from '@indico-data/utils';

import { DragDropVerticalList, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { DragDropListItem } from './OldDragDropListItem';

import { StyledOldDragDropList, StyledAddItemContainer } from './OldDragDropList.styles';

type Props = PermafrostComponent & {
  addButtonProps?: { 'data-cy': string };
  arrows?: boolean; //arrow buttons render by default, but can be removed by passing false
  canDelete: boolean;
  confirmButtonProps?: { 'data-cy': string };
  header?: string | React.ReactNode;
  inputProps?: { 'data-cy': string };
  items: { id: number; name: string }[];
  onAdd?: (args: string[]) => void; //optional - trash icon will not render if nothing passed
  onDelete?: (id: number) => void; //optional - trash icon will not render if nothing passed
  onEdit?: (args: string[]) => void; //optional - edit icon will not render if nothing passed
  onMovedItem: (args: string[]) => void; //required - function to be fired when item is drag/dropped or moved via up/down arrows
  subHeader?: string | React.ReactNode;
  removableTargetNames?: { id: number; name: string }[];
};

export type UpdateItemOrder = {
  destination?: number;
  source: number;
};

export const OldDragDropList = (props: Props) => {
  const {
    id,
    arrows = true,
    canDelete,
    className,
    header,
    items,
    onAdd,
    onDelete,
    onEdit,
    onMovedItem,
    subHeader,
    removableTargetNames,
  } = props;
  const [draggedItem, setDraggedItem] = useState('');

  // adding new items to the list
  const [newItemOpen, setNewItemOpen] = useState(false);
  const [newValue, setNewValue] = useState('');

  // tracks whether a reordering arrow has been pressed, and which items are affected
  const [swapItems, setSwapItems] = useState<[number | undefined, number | undefined]>([
    undefined,
    undefined,
  ]);

  // updateItemOrder is called on arrow button up/down
  const updateItemOrder = ({ destination, source }: UpdateItemOrder) => {
    if (destination === undefined || destination === source) {
      return;
    }

    setSwapItems([source, destination]);

    const reorderedItems = arrayUtils.reorderItem(
      items.map((item) => item.name),
      source,
      destination
    );

    setTimeout(() => {
      onMovedItem(reorderedItems);
      setSwapItems([undefined, undefined]);
    }, 300);
  };

  const addItem = () => {
    if (newValue !== '' && newValue.length >= 2) {
      const current = [...items.map((item) => item.name), newValue];

      onAdd && onAdd(current);

      // clear state and close “add item” input once added
      setNewValue('');
      setNewItemOpen(false);
    }
  };

  const editItem = (itemToEdit: string, editedItem: string) => {
    const current = [...items.map((item) => item.name)];
    const itemIndex = current.indexOf(itemToEdit);

    current[itemIndex] = editedItem;

    onEdit && onEdit(current);
  };

  // determines reordering direction, and applies appropriate css class
  const animateDirection = (itemIndex: number): string => {
    const sourceIndex = swapItems[0];
    const destIndex = swapItems[1];

    if (Number.isInteger(sourceIndex) && Number.isInteger(destIndex) && sourceIndex !== destIndex) {
      // @ts-ignore
      const direction = sourceIndex > destIndex ? 'up' : 'down';

      const itemIsSource = itemIndex === sourceIndex;
      const itemIsDest = itemIndex === destIndex;

      if (itemIsSource) {
        return direction === 'up' ? 'animate-up' : 'animate-down';
      }

      if (itemIsDest) {
        return direction === 'up' ? 'animate-down' : 'animate-up';
      }
    }

    return '';
  };

  let disabledDeleteFieldIds: number[] | null = null;
  if (removableTargetNames) {
    const removeableIdsArray = removableTargetNames.map(
      (removableTargetName) => removableTargetName.id
    );
    const itemIds = items.map((item) => item.id);
    disabledDeleteFieldIds = itemIds.filter((id) => !removeableIdsArray.includes(id));
  }

  return (
    <StyledOldDragDropList
      id={id}
      className={className}
      data-cy={props['data-cy']}
      style={!header && !subHeader ? { borderLeft: 'none' } : {}}
    >
      {header && (
        <h4 className="list-header t-bold" data-testid="main-header">
          {header}
        </h4>
      )}
      {subHeader && (
        <h5 className="sub-header" data-testid="sub-header">
          {subHeader}
        </h5>
      )}

      <DragDropVerticalList
        itemIdList={items.map((item) => item.name)}
        items={items.map((item, i) => (
          <DragDropListItem
            key={item.id}
            arrows={arrows}
            canDelete={canDelete}
            className={classNames(animateDirection(i))}
            disableDelete={disabledDeleteFieldIds ? disabledDeleteFieldIds.includes(item.id) : true}
            index={i}
            isDragging={item.name === draggedItem}
            item={item}
            listLength={items.length}
            onDelete={onDelete}
            onEdit={editItem}
            updateItemOrder={updateItemOrder}
          />
        ))}
        itemsVerticalGap="3px"
        onDrop={onMovedItem}
        updateDraggedItem={setDraggedItem}
      />

      {onAdd && (
        <StyledAddItemContainer className={!newItemOpen ? 'dash-border' : 'solid-back'}>
          {!newItemOpen && (
            <button
              className="add-btn"
              onClick={() => setNewItemOpen(true)}
              aria-label="add list item"
              {...props?.addButtonProps}
            >
              <Icon name="plus" size={[20]} />
            </button>
          )}

          {newItemOpen && (
            <>
              <input
                type="text"
                autoFocus={true}
                className="add-item-input"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter' && !items.some((item) => item.name === newValue)) {
                    e.preventDefault();

                    addItem();
                  }
                }}
                {...props?.inputProps}
              />
              <button
                aria-label="add new item"
                className="confirm"
                onClick={() => addItem()}
                disabled={
                  newValue === '' ||
                  newValue.length < 2 ||
                  items.some((item) => item.name === newValue)
                }
                {...props?.confirmButtonProps}
              >
                <Icon name="check" size={[18]} />
              </button>

              <button
                aria-label="cancel new item"
                className="cancel"
                onClick={() => {
                  setNewItemOpen(false);
                  setNewValue('');
                }}
              >
                <Icon name="x-close" size={[16]} />
              </button>
            </>
          )}
        </StyledAddItemContainer>
      )}
    </StyledOldDragDropList>
  );
};
