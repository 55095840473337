import styled from 'styled-components';

import { COLORS, FONT_SIZE } from 'Permafrost/index';

export const StyledSelect = styled.div`
  display: inline-block;

  .Select__container {
    position: relative;
    z-index: 0;

    select {
      appearance: none;
      background: transparent;
      border: none;
      z-index: 1;

      &:focus {
        outline: 0;
      }
    }

    option {
      color: ${COLORS.black};

      &:not(:checked) {
        color: ${COLORS.black};
      }
    }

    .chevron {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    &.dark {
      select {
        color: ${COLORS.defaultFontColor};
      }
    }

    &.light {
      select {
        color: ${COLORS.black};
      }

      .chevron {
        color: ${COLORS.black};
      }
    }

    &.small {
      select {
        font-size: ${FONT_SIZE.base};
        padding: 0;
        padding-right: 15px;
      }

      .chevron {
        right: 0px;
      }
    }

    &.medium {
      font-size: ${FONT_SIZE.subheadSmall};
      padding: 7px 35px 8px 15px;

      .chevron {
        right: 12px;
      }
    }
  }
`;
